@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

html, body{
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: hidden;
  font-family: 'Ubuntu', sans-serif;
}
#root{
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button{
  opacity: 1;  
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 24px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: opacity 0.3s ease-in-out;
}
.button:hover{
  opacity: 0.85;
}
.button1 {background-color: #4CAF50;} /* Green */
.button2 {background-color: #008CBA;} /* Blue */
.button3 {background-color: #f44336;} /* Red */
.button4 {background-color: #e7e7e7; color: black;} /* Gray */
.button5 {background-color: #555555;} /* Black */

.content-wrapper{
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.editor-header{
  z-index: 1;
  display: flex;
  padding: 16px 24px;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: 0 4px 7px 0 rgba(0,0,0,0.07);
}
.editor-header h1{
  flex: 1;
  margin: 0;
  padding: 0;
  color: #231f20;
  text-align: left;
}
.editor-header .btn-container{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.editor-header .btn-container button:last-child{
  margin-left: 5px;
}
.editor-title{
  display: flex;
  align-items: center;
}
.editor-title img{
  top: -5px;
  width: 42px;
  position: relative;
  margin-right: 10px;
}
.editor-wrapper{
  flex: 1 1 0%;
  display: flex;
  min-height: 500px;
  position: relative;
}
.overlay-box-middle{
  bottom: 0;
  right: 72px;
  width: 353px;
  height: 50px;
  position: absolute;
  background-color: #f9f9f9;
}
.overlay-box-right{
  right: 0;
  bottom: 0;
  width: 72px;
  height: 50px;
  position: absolute;
  background-color: #d6d9dc;
}

@media screen and (max-width: 1170px){
  .overlay-box {
      width: 279px;
  }
}